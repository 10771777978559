/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Title, Button, Subtitle, Fullmap, FullmapWrap, FullmapCover, ContactForm, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="mb--20" name={"introduction"}>
          
          <ColumnWrap className="column__flex --center mb--60 mt--16">
            
            <ColumnWrapper >
              
              <Image className="mb--20" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kadernictvi/dcc8f590acaf4d2f9dcbe3cfe1a9a7dc.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":450}} srcSet={""} content={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box swpf--uppercase ls--20" style={{"color":"rgb(0, 0, 0)"}} content={"BARBER PARLOUR & SOCIAL CLUB"}>
              </Text>

              <Title className="title-box fs--72 swpf--uppercase mt--02" content={"CLUB & BARBERSHOP"}>
              </Title>

              <Text className="text-box w--500 mt--20" style={{"maxWidth":900}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the maximum width of a block of text may be customized.  "}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 w--600 swpf--uppercase mt--25" href={"#pricelist"} content={"Group discounts"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/55/fullscreen_s=350x_.jpg"} svg={false} ratio={"16:9"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"our-team"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":1160}} columns={"4"}>
            
            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"LUĎĚK KRKINA"}>
              </Title>

              <Text className="text-box mt--16" content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#booking"} content={"ORDER"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"LAĎA KONSTIČKA"}>
              </Title>

              <Text className="text-box mt--16" content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#booking"} content={"ORDER"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"ADÉLA HOLISTROVÁ"}>
              </Title>

              <Text className="text-box mt--16" content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#booking"} content={"ORDER"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"TOMÁŠ BŘEZINA"}>
              </Title>

              <Text className="text-box mt--16" content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#booking"} content={"ORDER"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ior5lm bg--center --parallax pb--80 pt--80" name={"booking"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--style1 pb--40 pl--20 pr--20 pt--40" style={{"maxWidth":520,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box title-box--style1 swpf--uppercase" content={"YEARS OF PRACTICE"}>
              </Title>

              <Text className="text-box mt--16" style={{"color":"rgb(0,0,0)","maxWidth":380}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 swpf--uppercase mt--16" href={"#booking"} content={"ORDER"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"pricelist"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1160}} columns={"1"}>
            
            <ColumnWrapper className="--style1 pl--0 pr--0">
              
              <Title className="title-box title-box--style1 swpf--uppercase" content={"BARBERSHOP SINCE 1991"}>
              </Title>

              <Text className="text-box mt--16" style={{"color":"rgb(0,0,0)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the maximum width of a block of text may be customized.  "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--60 pb--30" name={"our-team-2"} layout={"l12"}>
          
          <ColumnWrap className="column__flex el--4 flex--center" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--30 pr--30 pt--20">
              
              <Title className="title-box swpf--uppercase ls--01" content={"FULL SERVICE"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— $17"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/55/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--10 pl--30 pr--30 pt--10">
              
              <Title className="title-box swpf--uppercase ls--01" content={"HAIR TRIMMING"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— $17"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--10 pl--30 pr--30 pt--10">
              
              <Title className="title-box swpf--uppercase ls--01" content={"BEARD TRIMMING"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— $17"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/55/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--10 pl--30 pr--30 pt--10">
              
              <Title className="title-box swpf--uppercase ls--01" content={"MACHINE CUTTING"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— $17"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-b4w48f bg--top --parallax pb--60 pt--60" name={"contact"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box title-box--invert swpf--uppercase" style={{"maxWidth":360}} content={"Contact"}>
              </Title>

              <Text className="text-box text-box--invert mt--16" style={{"maxWidth":360}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the maximum width of a block of text may be customized.  "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"510-851-4014"}>
              </Subtitle>

              <Text className="text-box mt--16" style={{"maxWidth":360}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Subtitle className="subtitle-box mt--16" style={{"maxWidth":360}} content={"info@vase-stranky.com"}>
              </Subtitle>

              <Text className="text-box mt--16" style={{"maxWidth":360}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--50 pt--40" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box swpf--uppercase" style={{"maxWidth":360}} content={"CLUB & BARBERSHOP"}>
              </Title>

              <Text className="text-box mt--16" style={{"maxWidth":360}} content={"722 Finch Street, Asbury Park, NJ 07712"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":360}} content={"NJ 07712"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":360}} content={"USA"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert" content={"Show on map"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="full-map full-map--style2 pb--30" name={"map"} style={{"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <FullmapWrap className="full-map__wrap--full full-map__wrap">
            
            <FullmapCover className="full-map__cover" place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--30 pt--30" name={"booking"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box swpf--uppercase" content={"ORDER"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--08 pt--20" menu={true} name={"footer"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--1 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"BARBER PARLOUR & SOCIAL CLUB"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"CLUB & BARBERSHOP"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"#pricelist"} target={""} content={"Group discounts"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"#contact"} target={""} content={"Contact"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pt--10" name={"footer-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footer-3"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--16 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}